// import ProgressCircle from 'react-native-progress-circle';
//import CircularProgress from 'react-native-circular-progress-indicator';

import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';

class Evidence_list extends Component {
render() {
    return (
        <div></div>
        // <ProgressCircle
        //     percent={30}
        //     radius={50}
        //     borderWidth={8}
        //     color="#3399FF"
        //     shadowColor="#999"
        //     bgColor="#fff"
        // >
        //   {'30%'} 
        // </ProgressCircle>
    )
}
}

export default withTranslation('common')(Evidence_list);