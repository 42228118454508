import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import Gallery from 'react-grid-gallery';
import "video-react/dist/video-react.css";
import { Player } from 'video-react';
import ReactAudioPlayer from 'react-audio-player';
const IMAGES =
[{
        src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
        thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        isSelected: true,
        caption: "After Rain (Jeshu John - designerspics.com)"
},
{
        src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
        thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        tags: [{value: "Ocean", title: "Ocean"}, {value: "People", title: "People"}],
        caption: "Boats (Jeshu John - designerspics.com)"
},
 
{
        src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
        thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
}]
class Evidence_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      contacts: [],
      images:[],
      videos:[],
      audios:[]
    }
    this.flag = 0;
    
    
  } 

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
    fetch(myConstClass.BASE_URL+'/user/getUserInfo/'+answer_array['3'])
    .then(response => response.json())
    .then(response =>this.setState({posts: response}))
    .catch(err => console.log(err))

    fetch(myConstClass.BASE_URL+'/user/getUserEmergencynfo/'+answer_array['3'])
    .then(response => response.json())
    .then(response =>this.setState({contacts: response}))
    .catch(err => console.log(err))

    fetch(myConstClass.BASE_URL+'/user/getUserImages/'+answer_array['3'])
    .then(response => response.json())
    .then(response =>this.setState({images: response}))
    .catch(err => console.log(err))

    fetch(myConstClass.BASE_URL+'/user/getUserVideos/'+answer_array['3'])
    .then(response => response.json())
    .then(response =>this.setState({videos: response}))
    .catch(err => console.log(err))
    

    fetch(myConstClass.BASE_URL+'/user/getUserAudios/'+answer_array['3'])
    .then(response => response.json())
    .then(response =>this.setState({audios: response}))
    .catch(err => console.log(err))
    
    }

    if(!localStorage.getItem('session_id')){
      console.log('History:',this.props);      
      this.props.history.push('/log_in');
  }
        
  }

render() {
  const { posts,images,videos,audios,contacts } = this.state;
  const heading = posts.first_name+' '+posts.last_name+'('+posts.sap_code+')';
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Evidence - {heading}
       </h3>       
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
      <CardBody>
      <Row>
      <Col md={12} lg={12}>
      <h3>
      Images
      </h3>
      <Gallery images={images}/>
      </Col>
      </Row>
      <hr/>
      <Row>
      <Col md={4} lg={4}>
      <h3>
       Video
      </h3>
      </Col>
      <Col md={8} lg={8}>
      <h3 style={{'margin-left': '15px'}}>
       Audio
      </h3>
      </Col>
      </Row>
      <Row>
      { videos.map((video, index) =>
      <Col md={4} lg={4} style={{"margin-bottom":'10px'}}>
      <Player
      playsInline
      poster="/assets/poster.png"
      src={video.file_path}
      width= {320}
      height= {212}
      fluid={false}
    /> </Col>
      )}

      
      <Col md={8} lg={8} style={{"margin-bottom":'10px'}}>
      <Col md={12} lg={12}>
      { audios.map((audio, index) =>
      <ReactAudioPlayer
      src={audio.file_path}
      controls
      
      />)}
      </Col><br/> 
      <Col md={12} lg={12}>
        <h3>Emergency Contacts</h3><br/>
      { contacts.map((audio, index) =>
        <div><h5 style={{"margin-bottom":'5px'}}>{index+1}.&nbsp;&nbsp;{audio.first_name+' '+audio.last_name}&nbsp;&nbsp;{'|'}&nbsp;&nbsp;{'SAP ID: '+audio.sap_code}&nbsp;&nbsp;{'|'}&nbsp;&nbsp;{'Mobile No: '+audio.mobile_no}</h5></div>
      )}
      </Col>
    </Col>
    
  
      </Row>

       
      </CardBody>
      </Card>
      </Col>
    </Row>
  </Container>)
}
}

export default withTranslation('common')(Evidence_view);
