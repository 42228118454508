import React,{ Component } from 'react';
import { Card, CardBody, CardTitle,CardSubtitle,CardText, Col, Container, Row,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider , { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from 'jquery'; 
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
var CryptoJS = require("crypto-js");

const { SearchBar } = Search;
const tableColumns = [ 
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerStyle: { width:"10%" }
  },  
  {
      dataField: "first_name",
      text: "Name",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "mobile_no",
      text: "Mobile",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "sap_code",
      text: "SAP ID",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "user_lat",
      text: "Lattitude",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "user_long",
      text: "Logitude",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "created_at",
      text: "Date/Time",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "action",
      text: "Action",
      headerStyle: { width:"10%" },
      csvExport:false
    },
  ];

  const image = `${process.env.PUBLIC_URL}/img/map-location.png`;



class Evidence_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData:[],
    }
    this.encode = this.encode.bind(this);
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'idd', filterable: true },
      { title: 'Employee Name', prop: 'first_name', filterable: true },
      { title: 'Employee SAP Code', prop: 'sap_code' },
      { title: 'Employee Mobile No.', prop: 'mobile_no' },
      { title: 'Employee Email', prop: 'email' },
      { title: 'Latitude', prop: 'user_lat'},
      { title: 'Longitude', prop: 'user_long'},
      { title: 'Date', prop: 'date'},
      { title: 'Time', prop: 'time'},
      { title: 'Panic Evidence', prop: 'action',cell: (row)=><div><center><i id={row.id} className="fa fa-eye ptr_css" onClick={() =>this.view(row.id)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + row.user_lat +","+row.user_long}><img id={row.id} src={image} style={{width: '20px',height: '16px'}} /></a></center></div> },
      ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    
  }

  view(e) { 
    this.props.history.push('/forms/evidence_view/'+e);
  }

downloadpdf(e) {
    toast('PDF file is generating.', {containerId: 'B',type: toast.TYPE.SUCCESS})
    const data = new FormData() 
    data.append('e_id', e);
    fetch(myConstClass.BASE_URL+`/user/downloadpdf`, {
        method: 'POST',
        body: data,
    }).then(response =>  response.json()).then((response) => {
        if(response){
        //  toast('Pdf is generating', {containerId: 'B',type: toast.TYPE.SUCCESS})
            window.open(response.pdf_url, "_blank");
        } else{
            //toast('Pdf generating Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
        }
    })
    .catch();
}

downloadzip(e) {
    toast('Zip file is generating.', {containerId: 'B',type: toast.TYPE.SUCCESS})
    const data = new FormData() 
    data.append('e_id', e);
    fetch(myConstClass.BASE_URL+`/user/downloadzip`, {
    method: 'POST',
    body: data,
    }).then(response =>  response.json()).then((response) => {
        if(response){
        //  toast('Pdf is generating', {containerId: 'B',type: toast.TYPE.SUCCESS})
            window.open( response.zip_url, 'Download');
        }else{
            //toast('Zip file generating Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
        }
    })
    .catch();
}

  edit(e) {    
   // alert(e);
    this.props.history.push('/forms/add/'+e);
   }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {

  //  fetch(myConstClass.BASE_URL+'/user/testfunctionfor')
  //  .then(response => response.json())
  //   .then(response =>{
  //     console.log( response.a);
  //    // console.log( hex2bin(response.a));
  //     var hex = response.a, // ASCII HEX: 37="7", 57="W", 71="q"
  //       bytes = [],
  //       str;
  //     for (var i = 0; i < hex.length - 1; i += 2) {
  //       bytes.push(parseInt(hex.substr(i, 2), 16));
  //     }
  //     str = String.fromCharCode.apply(String, bytes);
  //     console.log(str);
  //   })
  // .catch(err => console.log(err))

    // fetch(myConstClass.BASE_URL+'/user/getUserEvidence')
    fetch(myConstClass.BASE_URL+'/user/getUserEvidence')
    .then(response => response.json())
    .then(response =>{
      // let i=1;
      // response.forEach(element => {
      //   var id = element.id;
      //   //element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() =>this.view(id)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat +","+element.user_long}><img id={id} src={image} style={{width: '20px',height: '16px'}} /></a></center></div>;
      //   element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() =>this.view(id)}></i>&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat +","+element.user_long}><img id={id} src={image} style={{width: '20px',height: '16px'}} /></a>&nbsp;&nbsp;<i id={id} className="fa fa-file-pdf-o ptr_css" onClick={() =>this.downloadpdf(id)}></i>&nbsp;<i id={id} className="fa fa-download ptr_css" onClick={() =>this.downloadzip(id)}></i></center></div>;
      //   element.id = i;
      //   i++;
      // });
      // this.setState({posts: response})
        const responseNewArray = response.map(item => ({
          id: atob(item.id),
          first_name: atob(item.first_name),
          sap_code: atob(item.sap_code),
          email: atob(item.email),
          mobile_no: atob(item.mobile_no),
          user_lat: atob(item.user_lat),
          user_long: atob(item.user_long),
          created_at: atob(item.created_at),

          // id: this.binhx(item.id),
          // first_name: this.binhx(item.first_name),
          // sap_code: this.binhx(item.sap_code),
          // email: this.binhx(item.email),
          // mobile_no: this.binhx(item.mobile_no),
          // user_lat: this.binhx(item.user_lat),
          // user_long: this.binhx(item.user_long),
          // created_at: this.binhx(item.created_at),
        }));
        let ii=1;
          responseNewArray.forEach(element => {
          let id=element.id;
          element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() =>this.view(id)}></i>&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat +","+element.user_long}><img id={id} src={image} style={{width: '20px',height: '16px'}} /></a>&nbsp;&nbsp;<i id={id} className="fa fa-file-pdf-o ptr_css" onClick={() =>this.downloadpdf(id)}></i>&nbsp;<i id={id} className="fa fa-download ptr_css" onClick={() =>this.downloadzip(id)}></i></center></div>;
          element.id = ii;
          ii++;
        });
      this.setState({formData: responseNewArray})
      // this.setState({formData: response})
    })
    .catch(err => console.log(err))

    if(!localStorage.getItem('session_id')){
      console.log('History:',this.props);      
      this.props.history.push('/log_in');
  }
        
  }
  binhx(txt) {
    var hex = txt, // ASCII HEX: 37="7", 57="W", 71="q"
      bytes = [],
      str;
    for (var i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return str = String.fromCharCode.apply(String, bytes);
  }
  encode(txt){
   var passphrase = "passphrase";
    //  var encrypted_json_string ={"ciphertext":"slK9cfydbX\/0oH3l4xnt6g==","iv":"eecce0f76446e862a19fcd1f3c17ff75","salt":"b219654ab7da3c05735c56d16b4d20e984b1a9c3e7db07700dd5baf92c9bb48dafd6fd14783561e1639cb61720b99588e4bf35af11d4d090bb4fe8712f5086a2cb90ea98a545e8209c518c778c704d1ebb93781b0a880f43bd6bd3723d8f42d0a2125fb3828939571a76037556c657d8f30d7e0ece0dbaab36fed89ad7e298107ccffcbbf9af1626282f72848934b545473c33b2a67731787203e5d72752f6cb8bd9e79179d76f250e9f4e724abba7d2e1d7e3bba99192c5694499b3047b8ced8bab5552e283a1dcc3febf7507c8b4981c634e762208846c2e3e5ef79e80625824a637b40a512c9327be052a354d98ddad966ce8f569b192965fea8d27b4ac6f"};
    var obj_json = JSON.parse(txt);
    // var encrypted = obj_json.ciphertext;
    var encrypted = obj_json.ct;
    // var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    var salt = CryptoJS.enc.Hex.parse(obj_json.st);
    var iv = CryptoJS.enc.Hex.parse(obj_json.iv);
    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

render() {
  
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All Evidences
       </h3>
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
      {/* <CardBody> */}

      <ToolkitProvider
              keyField="name"
              data={this.state.formData}
              columns={tableColumns}
              exportCSV
              search
            >
              {props => (
                    <div>
                        
                          <CardBody>
                          <SearchBar { ...props.searchProps } />
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              bordered={false}
                              pagination={paginationFactory({
                                // sizePerPage: 25,
                                sizePerPage: 10,
                                sizePerPageList: [5, 10, 25, 50]
                              })}
                            />
                          </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
{/* <MDBDataTable
      striped
      hover
      data={this.state.posts}
    /> */}
    
      {/* </CardBody> */}
      </Card>
      </Col>
    </Row>
  </Container>)
}
}

export default withTranslation('common')(Evidence_list);
