import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { Field, reduxForm } from 'redux-form';
import FileUploadDefault from './components/FileUploadDefault';
import FileUploadCustomHeight from './components/FileUploadCustomHeight';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import $ from 'jquery';
import './custom.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import CsvDownloader from 'react-csv-downloader';


import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;
const columns = [{
  id: 'id',
  displayName: 'ID'
}, {
  id: 'sap_code',
  displayName: 'SAP Code'
}, {
  id: 'first_name',
  displayName: 'First Name'
}, {
  id: 'email',
  displayName: 'email'
}, {
  id: 'mobile',
  displayName: 'mobile'
}, {
  id: 'designation',
  displayName: 'designation'
}, {
  id: 'department',
  displayName: 'department'
}, {
  id: 'gender',
  displayName: 'gender'
}, {
  id: 'dob',
  displayName: 'dob'
}, {
  id: 'branch_name',
  displayName: 'branch_name'
}, {
  id: 'age',
  displayName: 'age'
}, {
  id: 'status',
  displayName: 'status'
}, {
  id: 'action',
  displayName: 'action'
}, {
  id: 'error',
  displayName: 'error'
}];
const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "sap_code",
    text: "SAP Code",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "first_name",
    text: 'Name',
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "mobile",
    text: "Mobile",
    headerStyle: { width: "10%" },
    csvExport: false
  }, {
    dataField: "designation",
    text: "Designation",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "department",
    text: 'Department',
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "gender",
    text: "Gender",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "dob",
    text: 'DOB',
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "branch_name",
    text: "Branch Code",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "age",
    text: "Age",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "status",
    text: "Status",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "action",
    text: "Action",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "error",
    text: "Error",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];

const file_link = `${process.env.PUBLIC_URL}/SAMPLE File.csv`;
const loader = `${process.env.PUBLIC_URL}/loader.gif`;
const rolling = `${process.env.PUBLIC_URL}/img/Rolling.gif`;

class Bulk_import extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      //posts: '',
      posts: [],
      dusers: '',
      uusers: '',
      posts_icnt:0,
      posts_ucnt:0,
      posts_ecnt:0
    }

    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'idd', filterable: true },
      { title: 'Employee Name   ', prop: 'first_name', filterable: true },
      { title: 'Employee SAP Code', prop: 'sap_code' },
      { title: 'Employee Mobile No.', prop: 'mobile_no' },
      { title: 'Employee Email', prop: 'email' },
      { title: 'Latitude      ', prop: 'user_lat' },
      { title: 'Longitude     ', prop: 'user_long' },
      { title: 'Date          ', prop: 'date' },
      { title: 'Time          ', prop: 'time' },
      { title: 'Panic Evidences', prop: 'action', cell: (row) => <div><center><i id={row.id} className="fa fa-eye ptr_css" onClick={() => this.view(row.id)}></i></center></div> },
    ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }
  handleSubmit = () => {
    alert(JSON.stringify($('#pform').serializeArray()));
  }
  onChangeHandler = event => {

    console.log(event.target.files[0]);
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })

  }
  onClickHandler = () => {
    const data = new FormData()
    data.append('file', this.state.selectedFile);
    data.append('session_id', localStorage.getItem('session_id'));
    $('#loader').show();
    fetch(myConstClass.BASE_URL + `/user/import`, {
      method: 'POST',
      body: data,
    }).then(response => response.json())
      .then(response => {
        // this.setState({ posts: response });
        this.setState({ posts_icnt: response['icnt'] });
        this.setState({ posts_ucnt: response['ucnt'] });
        this.setState({ posts_ecnt: response['ecnt'] });
        this.setState({ posts: response['rows'] });

        console.log(response['rows']);
        console.log(this.state.posts);
        $('#loader').hide();

        fetch(myConstClass.BASE_URL + `/user/getDeclinedUser`, {
          method: 'POST',
          body: data,
        }).then(response => response.json())
          .then(response => this.setState({ dusers: response }))


        fetch(myConstClass.BASE_URL + `/user/getUpdatedUser`, {
          method: 'POST',
          body: data,
        }).then(response => response.json())
          .then(response => this.setState({ uusers: response }))

      })

  }

  onSaveClickHandler = () => {
    const data = new FormData()
    data.append('file', this.state.selectedFile);
    data.append('session_id', localStorage.getItem('session_id'));
    fetch(myConstClass.BASE_URL + `/user/save_import_data`, {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((response) => {
      if (response) {
        toast('User Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
        this.props.history.push('/forms/users/');
      } else {
        toast('User Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    })
      .catch();


  }



  render() {

    return (
      <Container>
        <Row>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
          <Col md={12}>
            <h3 className="page-title">Employee Bulk Import</h3>
          </Col>
        </Row>
        <Card className="card-body">
          <h5>
            <b>Import CSV file only</b><br /><br />
            The first line in downloaded csv file should remain as it is. Please do not change the order of columns in csv file.
            <br />
            The correct column order is <br />(SAP Code(*), First Name(*), Middle Name, Last Name(*), Mobile(*), Email(*), designation, Department_Name, Gender(*), Blood Group, Age, DOB(*), Branch Code(*), Emergency Contact SAP Code1(*), Emergency Contact SAP Code2(*), Emergency Contact SAP Code3(*), Emergency Contact SAP Code4(*), Employee Status(*)
            ) <br />and you must follow the csv file, otherwise your data will not be saved.
          </h5>
          <hr />
        </Card>


        {this.state.posts && this.state.posts.length ?
           <Card className="card-body">
         <div>
            <Row>
              <Col md={9}>
                <h5>Insert : {this.state.posts_icnt} &nbsp;&nbsp; Update : {this.state.posts_ucnt} &nbsp;&nbsp; Error : {this.state.posts_ecnt}</h5>
              </Col>
              <Col md={3} >
                <CsvDownloader
                  filename="UpdatedUser"
                  separator=","
                  columns={columns}
                  datas={this.state.uusers}
                  className="btn btn-primary btn-block cmargin"
                  text="Updated User list" />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <CsvDownloader
                  filename="UserError"
                  separator=","
                  columns={columns}
                  datas={this.state.dusers}
                  className="btn btn-primary btn-block"
                  text="User Error list" />
              </Col>
            </Row>
            <hr /></div>
            </Card>
          :
          <Card className="card-body">
          <div>
            <form className="form" id="pform" onSubmit={this.handleSubmit}>
              <Col md={3}>
                <input type="file" name="file" onChange={this.onChangeHandler} />
              </Col>

              <Col md={6}>
                <Row>
                  <button type="button" style={{ width: '40%', height: '10%' }} className="btn btn-primary btn-block col-md-4" onClick={this.onClickHandler}>Upload</button>
                  <img src={loader} id="loader" style={{ width: '12%', display: 'none' }} />
                </Row>
              </Col>

              <Col md={3}>
                <a href={file_link} style={{ float: 'right' }}>
                  <Button color="primary" type="button" className="previous">Download Sample File</Button>
                </a>
              </Col> </form>  </div></Card>
        }
          {this.state.posts && this.state.posts.length ?
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.posts}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                        />
                      </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
                <div className='card-body'>
                  <button type="button" className="btn btn-primary btn-block col-md-2" onClick={this.onSaveClickHandler}>Save</button>
                </div>
              </Card>
            </Col>
          </Row>
          : ''}

      </Container>)
  }
}

Bulk_import.propTypes = {
  //handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
export default reduxForm({
  form: 'Bulk_import', // a unique identifier for this form
})(withTranslation('common')(Bulk_import));

