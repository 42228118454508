import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button, FormGroup, Label, Input} from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import { CSVLink, CSVDownload } from "react-csv";
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead ,paging,MDBPagination } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import '../../../common_db.css';
import 'font-awesome/css/font-awesome.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider , { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;

     
const tableColumns = [ 
   {
    dataField: "uid",
    text: "#",
    sort: true,
    headerStyle: { width:"10%" }
   },  
   {
      dataField: "first_name",
      text: "Name",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "mobile_no",
      text: "Mobile No",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "designation",
      text: "Designation",
      headerStyle: { width:"10%" },
      csvExport:false
    },  {
      dataField: "branch_name",
      text: "Branch Name",
      headerStyle: { width:"10%" },
      csvExport:false
    },
    {
      dataField: "department",
      text: "Department",
      headerStyle: { width:"10%" },
      csvExport:false
    },
    {
      dataField: "gender",
      text: "Gender",
      headerStyle: { width:"10%" },
      csvExport:false
    },
    {
      dataField: "dob",
      text: "DOB",
      headerStyle: { width:"10%" },
      csvExport:false
    }, 
    {
      dataField: "status",
      text: "Status",
      headerStyle: { width:"10%" },
      csvExport:false
    },
    {
      dataField: "action",
      text: "Actions",
      headerStyle: { width:"10%" },
      csvExport:false
    },
  ];

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      array_t:[],
      tableRows:[],
      formData:[],
      formDataCSV:''
    };
    this.change = this.change.bind(this);
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'id', filterable: true },
      { title: 'Employee Name', prop: 'first_name',filterable: true },
      { title: 'Email', prop: 'email' },
      { title: 'Mobile No.', prop: 'mobile_no'},
      { title: 'Designation', prop: 'designation' },
      { title: 'Branch', prop: 'branch_name'},
      { title: 'Department', prop: 'department' },
      { title: 'Vertical', prop: 'vertical'},
      { title: 'Gender', prop: 'gender'},
      { title: 'Date of Birth', prop: 'date_of_birth'},
      { title: 'Age', prop: 'age'},
      { title: 'Status', prop: 'status'},
      { title: 'Action', prop: 'action',cell: (row)=><div><i id={row.id} className="fa fa-remove ptr_css" onClick={() =>this.remove(row.id)}></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-edit ptr_css" onClick={() =>this.edit(row.id)}></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-eye ptr_css"></i></div> },
      ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };

    
  }

  remove(e) { 
    this.flag = 1;
    fetch(myConstClass.BASE_URL+`/user/deleteUser`, {
    method: 'POST',
    body: e,
  }).then(toast('User Deleted Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS}),

  // fetch(myConstClass.BASE_URL+'/user/getUsers')
  fetch(myConstClass.BASE_URL+'/user/getUsersEncode')
  .then(response =>response.json()
).then(response =>{
  const responseNewArray = response.map(item => ({
    uid: atob(item.uid),
    first_name: atob(item.first_name),
    email: atob(item.email),
    mobile_no: atob(item.mobile_no),
    designation: atob(item.designation),
    branch_name: atob(item.branch_name),
    department: atob(item.department),
    gender: atob(item.gender),
    date_of_birth: atob(item.date_of_birth),
    status: atob(item.status),
  }));
    let i=1;  
    responseNewArray.forEach(element => {
    let id=element.uid;
  element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() =>this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() =>this.edit(id)}></i></div>;
   // console.log(element);
   element.uid = i;
   i++;
  });
  // this.setState({posts: response})
  this.setState({formData: responseNewArray})
})
.catch(err => console.log(err))
  )
  .catch();

  
  }

  change(event){
    let arr;
    fetch(myConstClass.BASE_URL+'/user/getUsers/'+event.target.value)
    .then(response => response.json())
    .then(response =>{
      let i=1;
      response.forEach(element => {
        let id=element.uid;
      element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() =>this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() =>this.edit(id)}></i></div>;
       // console.log(element);
       arr = this.state.array_t;
       arr.push(element);
       this.setState({array_t: arr});
       element.uid=i;
       i++;
      });
      // this.setState({posts: response})
      this.setState({formData: response})
      
    })
    .catch(err => console.log(err))
        
  }

  edit(e) {    
    //alert(e);
    this.props.history.push('/forms/add/'+e);
   }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    let arr;
    /*
    fetch(myConstClass.BASE_URL+'/user/getUsers')
    .then(response => response.json())
    .then(response =>{
         
      let i=1;
      response.forEach(element => {
        let id=element.uid;
        element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() =>this.remove(id)}></i><i id={id} onClick={() =>this.edit(id)} className="fa fa-edit ptr_css"></i></div>;
    
        // console.log("Inner = ");
        // console.log(element); 
      
        arr = this.state.array_t;
        arr.push(element);
        this.setState({array_t: arr});
        element.uid=i;
       i++;
      });
      // this.setState({posts:response})
      this.setState({formData: response})
      // this.setState({ tableRows:this.assemblePosts() })
     
      // console.log("posts2 : ");
    
    //  this.setState({array_m:posts2})
  
      // this.setState({ tableRows:this.assemblePosts(), isLoading:false })
    })
    .catch(err => console.log(err))
    */

    // added 20-7-2021
    fetch(myConstClass.BASE_URL+'/user/getUsersEncode')
    .then(response => response.json())
    .then(response =>{
      const responseNewArray = response.map(item => ({
        uid: atob(item.uid),
        first_name: atob(item.first_name),
        email: atob(item.email),
        mobile_no: atob(item.mobile_no),
        designation: atob(item.designation),
        branch_name: atob(item.branch_name),
        department: atob(item.department),
        gender: atob(item.gender),
        date_of_birth: atob(item.date_of_birth),
        status: atob(item.status),
      }));
      let i=1;
      responseNewArray.forEach(element => {
        let id=element.uid;
        element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() =>this.remove(id)}></i><i id={id} onClick={() =>this.edit(id)} className="fa fa-edit ptr_css"></i></div>;
        arr = this.state.array_t;
        arr.push(element);
        this.setState({array_t: arr});
        element.uid=i;
       i++;
      });
      this.setState({formData: responseNewArray})
    })
    .catch(err => console.log(err))


    fetch(myConstClass.BASE_URL+`/user/getUsersCSV`)
    .then(response => response.json())
    .then(response =>this.setState({formDataCSV: response}))
    
     if(!localStorage.getItem('session_id')){
      // console.log('History:',this.props);      
      this.props.history.push('/log_in');
     }
  }

  // assemblePosts= () => {

  //   let posts =this.state.posts.rows.map((post) => {
  //     return (
  //       {
  //           id: post.uid,
  //           name:  post.name,
  //           email:  post.email,
  //           mobile_no:  post.mobile_no,
  //           designation:  post.designation,
  //           branch_name: post.branch_name,
  //           department:  post.department,
  //           gender:  post.gender,
  //           dob:  post.dob,
  //           status:  post.status,
  //           action:  post.action,
  //       }
  //     )  
  //   });
  //   return posts;

  // }

render() {
  // console.log(this.state.posts.rows);
  // console.log(this.state.array_m);
  const headers = [
    { label: "#", key: "uid" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Mobile No.", key: "mobile_no" },
    { label: "Designation", key: "designation" },
    { label: "Branch", key: "branch_name" },
    { label: "Department", key: "department" },
    { label: "Gender", key: "gender" },
    { label: "DOB", key: "dob" },
    { label: "Status", key: "status" },
  ];
  
  const columns = [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Mobile No',
        field: 'mobile_no',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Designation',
        field: 'designation',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Branch Name',
        field: 'branch_name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Department',
        field: 'department',
        sort: 'asc',
        width: 100
      },
      {
        label: 'gender',
        field: 'gender',
        sort: 'asc',
        width: 100
      },
      {
        label: 'DOB',
        field: 'dob',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'asc',
        width: 100
      }
    ];

  // const data = {
  //    columns : [
  //     {
  //       label: '#',
  //       field: 'id',
  //       sort: 'asc',
  //       width: 150
  //     },
  //     {
  //       label: 'Name',
  //       field: 'name',
  //       sort: 'asc',
  //       width: 150
  //     },
  //     {
  //       label: 'Email',
  //       field: 'email',
  //       sort: 'asc',
  //       width: 270
  //     },
  //     {
  //       label: 'Mobile No',
  //       field: 'mobile_no',
  //       sort: 'asc',
  //       width: 200
  //     },
  //     {
  //       label: 'Designation',
  //       field: 'designation',
  //       sort: 'asc',
  //       width: 100
  //     },
  //     {
  //       label: 'Branch Name',
  //       field: 'branch_name',
  //       sort: 'asc',
  //       width: 150
  //     },
  //     {
  //       label: 'Department',
  //       field: 'department',
  //       sort: 'asc',
  //       width: 100
  //     },
  //     {
  //       label: 'gender',
  //       field: 'gender',
  //       sort: 'asc',
  //       width: 100
  //     },
  //     {
  //       label: 'DOB',
  //       field: 'dob',
  //       sort: 'asc',
  //       width: 100
  //     },
  //     {
  //       label: 'Status',
  //       field: 'status',
  //       sort: 'asc',
  //       width: 100
  //     },
  //     {
  //       label: 'Action',
  //       field: 'action',
  //       sort: 'asc',
  //       width: 100
  //     }
  //   ],
  //     // {
  //     //   id: '1',
  //     //   name: 'Tiger Nixon',
  //     //   email: 'System Architect',
  //     //   mobile_no: 'Edinburgh',
  //     //   designation: '61',
  //     //   branch_name: '2011/04/25',
  //     //   department: '$320',
  //     //   gender: '$320',
  //     //   dob: '$320',
  //     //   status: '$320'
  //     // },
      
  //     // this.state.array_t,
  //     rows:this.state.tableRows,

  //   }

  // console.log("MY array = ");
  // console.log(data); 
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All Users
        </h3>
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
    <ToolkitProvider
              keyField="name"
              data={this.state.formData}
              columns={tableColumns}
              exportCSV
              search
            >
     {props => (
     <div>
      <CardBody>
      <Row>
      <Col md={4} lg={4}></Col>
      
    <Col md={4} lg={4}>
      <FormGroup>
          {/* <Label for="exampleSelect">Status</Label> */}
          <Input type="select" name="select" id="exampleSelect" onChange={this.change}>
            <option value="0">Select Status</option>
            <option value="1">Active</option>
            <option value="2">In-active</option>
            <option value="3">Suspended</option>
          </Input>
        </FormGroup>
        </Col>
        <Col md={4} lg={4}>
        <Link to="/forms/add" style={{float:'right'}}> <Button
                    color="primary"
                    variant="contained"
                    type="button"
                   
                >Add Employee</Button>
                </Link>
                {this.state.formDataCSV ? 
                <CSVLink filename={"Users.csv"}
  className="btn btn-secondary" style={{'float':'right','margin': '0px 17px'}} data={this.state.formDataCSV} headers={headers}>Export</CSVLink>
  :''}      
  </Col>
        </Row>
        <hr style={{margin_bottom:'2px !important',margin_top:'0px !important'}} />
        {/* <Datatable
              tableHeader={this.header}
              tableBody={this.state.posts}
              keyName="userTable"
              tableClass="striped hover responsive"
              rowsPerPage={10}
              rowsPerPageOption={[5, 10, 15, 20]}
              initialSort={{prop: "`title`", isAscending: true}}
              // onSort={this.onSortFunction}
              // labels={this.customLabels}
              table
          /> */}

        {/*<MDBDataTable
              striped
              hover
              data={this.state.posts}
            /> */}

          {/* <MDBTable resposive>
            <MDBTableHead columns={columns} />
            <MDBTableBody rows={this.state.array_t} />
            <MDBPagination size={"sm"}/>
        </MDBTable> */}
       <SearchBar { ...props.searchProps } />
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              bordered={false}
                              pagination={paginationFactory({
                                // sizePerPage: 25,
                                sizePerPage: 10,
                                sizePerPageList: [5, 10, 25, 50]
                   })}
                 />
       </CardBody>
        </div>
           )}
        </ToolkitProvider>
      </Card>
      </Col>
    </Row>
  </Container>)
}
}



export default withTranslation('common')(Users);




