import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BasicForm from '../../../Form/BasicForm/index';
import CheckFormControls from '../../../Form/CheckFormControls/index';
import FileUpload from '../../../Form/FileUpload/index';
import FloatingLabelsForm from '../../../Form/FloatingLabelsForm/index';
import AboutUs from '../../../Form/AboutUs/index';
import Queries from '../../../Form/Queries/index';
import PrivacyPolicy from '../../../Form/PrivacyPolicy/index';
import Terms from '../../../Form/Terms/index';
import Help from '../../../Form/Help/index';
import Tutorial from '../../../Form/Tutorial/index';
import Admin from '../../../Form/Admin/index';
import Users from '../../../Form/Users/index';
import UsersList from '../../../Form/Users/user_list';
import Admin_list from '../../../Form/Admin_list/index';
import Evidence_list from '../../../Form/Evidence_list/index';
import Panic_evidence_list from '../../../Form/Evidence_list/panic_evidence_list_index';
import Cop_list from '../../../Form/Cop_list/index';
import Notifications from '../../../Form/Notifications/index';
import PreTriggerNotifications from '../../../Form/PreTriggerNotifications/index';
import Bulk_import from '../../../Form/Bulk_import/index';
import Location_import from '../../../Form/Location_import/index';
import Location_add from '../../../Form/Location_add/index';
import Location_group_add from '../../../Form/Location_group_add/index';
import Location_list from '../../../Form/Location_list/index';
import Group_location_list from '../../../Form/Group_location_list/index';
import Evidence_view from '../../../Form/Evidence_view/index';
import Cop_view from '../../../Form/Cop_view/index';
import FormDropzone from '../../../Form/FormDropzone/index';
import FormLayouts from '../../../Form/FormLayouts/index';
import FormPicker from '../../../Form/FormPicker/index';
import FormValidation from '../../../Form/FormValidation/index';
import MaskForm from '../../../Form/MaskForm/index';
import MaterialForm from '../../../Form/MaterialForm/index';
import WizardForm from '../../../Form/WizardForm/index';
import SendNotification from '../../../Form/SendNotification/index';
import Tracking from '../../../Form/Tracking/index';

//24-march-2022
import user_report from '../../../Form/MisReport/user_report';
import panic_alerts_report from '../../../Form/MisReport/panic_alerts_report';
import panic_alerts_report1 from '../../../Form/MisReport/panic_alerts_report1';

export default () => (
  <Switch>
    <Route path="/forms/basic_form" component={BasicForm} />
    <Route path="/forms/check_form_controls" component={CheckFormControls} />
    <Route path="/forms/file_upload" component={FileUpload} />
    <Route path="/forms/floating_labels_form" component={FloatingLabelsForm} />
    <Route path="/forms/add" component={FloatingLabelsForm} />
    <Route path="/forms/addbulk" component={Bulk_import} />
    <Route path="/forms/addLocation" component={Location_import} />
    <Route path="/forms/add_Location" component={Location_add} />
    <Route path="/forms/add_group_Location" component={Location_group_add} />
    <Route path="/forms/locations" component={Location_list} />
    <Route path="/forms/group_locations" component={Group_location_list} />
    <Route path="/forms/add/:id" component={FloatingLabelsForm} />
    <Route path="/forms/users" component={Users} />
    <Route path="/forms/users_list" component={UsersList} />

    <Route path="/forms/add_admin" component={Admin} />
    <Route path="/forms/add_admin/:id" component={Admin} />
    <Route path="/forms/admin_list" component={Admin_list} />
    <Route path="/forms/evidence_list" component={Evidence_list} />
    <Route path="/forms/panic_evidence_list" component={Panic_evidence_list} />
    <Route path="/forms/cop_list" component={Cop_list} />
    <Route path="/forms/notifications" component={Notifications} />
    <Route path="/forms/pre_trigger_notifications" component={PreTriggerNotifications} />
    <Route path="/forms/tracking" component={Tracking} />
    <Route path="/forms/queries" component={Queries} />
    <Route path="/forms/about_us" component={AboutUs} />
    <Route path="/forms/privacy_policy" component={PrivacyPolicy} />
    <Route path="/forms/help" component={Help} />
    <Route path="/forms/tutorial" component={Tutorial} />
    <Route path="/forms/terms" component={Terms} />
    {/* <Route path="/forms/loc_list" component={Location_list} /> */}
    <Route path="/forms/evidence_view" component={Evidence_view} />
    <Route path="/forms/cop_view" component={Cop_view} />
    <Route path="/forms/send_notification" component={SendNotification} />
    <Route path="/forms/form_dropzone" component={FormDropzone} />
    <Route path="/forms/form_layouts" component={FormLayouts} />
    <Route path="/forms/form_picker" component={FormPicker} />
    <Route path="/forms/form_validation" component={FormValidation} />
    <Route path="/forms/mask_form" component={MaskForm} />
    <Route path="/forms/material_form" component={MaterialForm} />
    <Route path="/forms/wizard_form" component={WizardForm} />

    {/* 24-march-2022 */}
    <Route path="/forms/MisReport/user_report" component={user_report} />
    <Route path="/forms/MisReport/panic_alerts_report" component={panic_alerts_report} />
    <Route path="/forms/MisReport/panic_alerts_report1" component={panic_alerts_report1} />
  </Switch>
);
