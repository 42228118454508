import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink, CSVDownload } from "react-csv";
import * as myConstClass from '../../../constant.js';
import '../../../common_db.css';
import 'font-awesome/css/font-awesome.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import $ from 'jquery';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      array_t: [],
      tableRows: [],
      formDataCSV: '',
      formData: {
        from_date: Date.now(),
        to_date: Date.now(),
      },
      response_data: [],
      isLoaded: true,
      date_div_flag: true,
      type_val: 1,
    };
    this.change = this.change.bind(this);
  }

  change(event) {

    this.setState({ type_val: event.target.value })

  }

  csvReport() {
    this.setState({ isLoaded: false })

    let arr;
    fetch(myConstClass.BASE_URL + '/user/user_panic_download', {
      method: 'POST',
      body: JSON.stringify($('#pform').serializeArray()),
    })
      .then(response => response.json())
      .then(response => {
        //        let i = 1;
        //        response.forEach(element => {
        //          let id = element.uid;
        //          arr = this.state.array_t;
        //          arr.push(element);
        //          this.setState({ array_t: arr });
        //          element.uid = i;
        //          i++;
        //        });
        console.log(response.length);
        if (response.length >= 1) {
          //   this.setState({ formDataCSV: response })
          this.setState({ formDataCSV: response }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLink.link.click();
            this.setState({ isLoaded: true })
          });
        } else {
          alert("No Record Found");
          this.setState({ isLoaded: true })
        }
      })
      .catch(err => console.log(err))
  }

  handleDateChange = (date) => {
    const { formData } = this.state;
    formData['from_date'] = date;
    this.setState({ formData });
  };
  handleDateChangeToDate = (date) => {
    const { formData } = this.state;
    formData['to_date'] = date;
    this.setState({ formData });
  };

  render() {
    const headers = [
      { label: "Sr. No", key: "sr_no" },
      { label: "Name", key: "name" },
      { label: "SAP Code", key: "sap_code" },
      { label: "Email", key: "email" },
      { label: "Mobile No.", key: "mobile_no" },
      { label: "Lat", key: "user_lat" },
      { label: "Long", key: "user_long" },
      { label: "Gender", key: "gender" },
      { label: "Blood group", key: "blood_group" },
      { label: "Panic request raised Date/Time", key: "created_at" },
      { label: "Image 1", key: "img1" },
      { label: "Image 2", key: "img2" },
      { label: "Video", key: "video1" },
      { label: "Audio", key: "audio1" },
    ];

    const columns = [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Mobile No',
        field: 'mobile_no',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Designation',
        field: 'designation',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Branch Name',
        field: 'branch_name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Department',
        field: 'department',
        sort: 'asc',
        width: 100
      },
      {
        label: 'gender',
        field: 'gender',
        sort: 'asc',
        width: 100
      },
      {
        label: 'DOB',
        field: 'dob',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 100
      },
    ];
    const { formData, isLoaded, date_div_flag } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Panic alerts report
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <form className="form" id="pform"  >
              <Card>
                <ToolkitProvider
                  keyField="name"
                  data={this.state.formData}
                  exportCSV
                  search
                >
                  {props => (
                    <div>
                      <CardBody>
                        <Row>
                          <Col md={4} lg={4}></Col>
                        </Row>

                        <Row>
                          <Col md={4} lg={4}></Col>

                          <Col md={4} lg={4}>
                            <FormGroup>
                              <Input type="select" name="type" id="type" onChange={this.change}>
                                {/* <option value="0">Select Option</option> */}
                                <option value="1">Panic Request</option>
                                <option value="2">Employee Cop</option>
                                <option value="3">All Request</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={4} lg={4}></Col>
                          <Col md={6} lg={6}>
                            <FormGroup>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  className="material-form__field"
                                  onChange={this.handleDateChange}
                                  name="from_date"
                                  placeholder="From Date"
                                  label="From Date"
                                  format="dd-MM-yyyy"
                                  value={formData.from_date}
                                />
                              </MuiPickersUtilsProvider>

                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  className="material-form__field"
                                  onChange={this.handleDateChangeToDate}
                                  name="to_date"
                                  placeholder="To Date"
                                  label="To Date"
                                  format="dd-MM-yyyy"
                                  value={formData.to_date}
                                />
                              </MuiPickersUtilsProvider>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4} lg={4}></Col>

                          <Col md={4} lg={4} style={{ 'textAlign': 'center' }} >

                            <button
                              color="primary" className="btn btn-primary"
                              type="button"
                              onClick={e => this.csvReport(e)}
                            > Download user report
                            </button>

                            <CSVLink
                              data={this.state.formDataCSV}
                              filename={"Panic_report.csv"}
                              headers={headers}
                              ref={(r) => (this.csvLink = r)}
                              target="_blank"
                            />
                            {!isLoaded ? (
                              <div style={{ display: 'flex' }}  >
                                <div className="load__icon-wrap">
                                  <svg className="load__icon">
                                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                  </svg>
                                </div>
                              </div>
                            ) : (""
                            )}

                            {/* <CSVLink filename={"Panic_report.csv"}
                              color="primary" className="btn btn-primary" data={this.state.formDataCSV}
                              onClick={() => this.csvReport()}
                              headers={headers}
                            >Download report</CSVLink>
                            */}
                          </Col>
                        </Row>
                        <hr style={{ margin_bottom: '2px !important', margin_top: '0px !important' }} />
                      </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Users);
