import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { MultipleSelect } from "react-select-material-ui";
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { Editor} from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,convertFromRaw  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
class SendNotification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      user_id:'',
      codes:[],
      branch_locations:[],
      editorState: EditorState.createEmpty(),
      formData: {
        notice_title:'',
        title: '',
        type:'1',
        branch_code: '',
        locations:[],
        branches:[]
      },
    submitted: false,
    isButtonDisabled:true,
    isButtonDisabled1:true,
    }
    
  }
  componentDidMount(){
   
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
      //alert(answer_array['3']);
      this.state.user_id = answer_array['3'];
      fetch(myConstClass.BASE_URL+'/user/getUserGroupLocationInfo/'+answer_array['3'])
      .then(response => response.json())
      .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))
      
    }
    fetch(myConstClass.BASE_URL+'/user/getGLocations/')
    .then(response => response.json())
    .then(response =>{this.setState({codes: response})}).catch(err => console.log(err))

    fetch(myConstClass.BASE_URL+'/user/getAllBranchCode/')
    .then(response => response.json())
    .then(response =>{this.setState({branch_locations: response})}).catch(err => console.log(err))

    if(!localStorage.getItem('session_id')){
      console.log('History:',this.props);      
      this.props.history.push('/log_in');
  }

  }

  handleLocationChange=(values) => {
   // console.log(values);
    const { formData } = this.state;
    formData['locations'] = values;
    this.setState({ formData });
  };

  handleRadioChange=(values) => {
   // alert(values);
    const { formData } = this.state;
    formData['type'] = values;
    this.setState({ formData });
    //alert(this.state.formData.type);
  };

  handleBranchChange=(values) => {
    //console.log(values);
    const { formData } = this.state;
    formData['branches'] = values;
    this.setState({ formData });
  };
  
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    //this.content =1;
    this.content = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
    //console.log('log:',{draftToHtml(convertToRaw(editorState.getCurrentContent()))});
  };

handleSubmit = () => {
 
  this.setState({ submitted: true }, () => {
      var result = {};
      result['notice_title']=this.state.formData.notice_title;
      result['api_content']=this.content;
      result['title']=draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
      result['locations']=this.state.formData.locations;
      result['branches']=this.state.formData.branches;
      result['type']=this.state.formData.type;
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL+`/user/sendNotification/`+this.state.user_id, {
              method: 'POST',
              body: JSON.stringify(result),
            }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toast('Location Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/notifications/');
              }else{
                toast('User Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
  });
}


  render() {
    const { formData, submitted,states,districts,countries,isButtonDisabled,isButtonDisabled1 } = this.state;
    const { codes,branch_locations,editorState} = this.state;    
    return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Send Notification</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
      </Col>
    </Row>
    <Row>   
     
    <Card>
      <CardBody>
        <div className="card__title">
          {/* <h5 className="bold-text">{t('forms.floating_labels_form.animated_line_inputs')}</h5>
           */}
        {/* <h5 className="bold-text">Add Admin/Employee {posts.sap_code}</h5> */}
        {/* <h5 className="subhead">Material fields with a property <span className="red-text">label</span></h5> */}
        </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
        </div>
        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >      
          <Row>
          <Col md={12} lg={12}>
          <Field
                  name="type"
                  component={renderRadioButtonField}
                  label="All"
                  radioValue="1"
                  value="1"
                  defaultChecked
                  onChange={this.handleRadioChange}
                />
          <Field
                  name="type"
                  component={renderRadioButtonField}
                  label="Group Location"
                  radioValue="2"
                  value="2"
                  onChange={this.handleRadioChange}
                />
          <Field
                  name="type"
                  component={renderRadioButtonField}
                  label="Location"
                  radioValue="3"
                  value="3"
                  onChange={this.handleRadioChange}
                />
          </Col>
            </Row>
          <Row>
          
        <Col md={6} lg={6}>            
            
         {this.state.formData.type == '2' ?
        <div><MultipleSelect
          label="Choose Group Locations"         
          values={formData.locations}
          options={codes}
          onChange={this.handleLocationChange}
          helperText="You can search a Location by writing its name and pressing enter"         
          SelectProps={{
            isCreatable: true,
            msgNoOptionsAvailable: "All Group Locations are selected",
            msgNoOptionsMatchFilter: "No Group location name matches the filter"
          }}
        />
         <br/></div> : ''}
         {this.state.formData.type == '3' ?
         <div>
         <MultipleSelect
          label="Choose Locations"         
          values={formData.branches}
          options={branch_locations}
          onChange={this.handleBranchChange}
          helperText="You can search a Location by writing its name and pressing enter"         
          SelectProps={{
            isCreatable: true,
            msgNoOptionsAvailable: "All Locations are selected",
            msgNoOptionsMatchFilter: "No location name matches the filter"
          }}
        />
         <br/></div>:''}
         <br/>
         <TextValidator
             onChange={this.handleChange}
             name="notice_title"          
             label="Title"
             value={formData.notice_title}
             validators={['required']}
             errorMessages={['this field is required']}
             className="material-form__field"
            /> 
            <br/>
        {/* <TextValidator
             onChange={this.handleChange}
             name="title"          
             label="Notification"
             value={formData.title}
             validators={['required']}
             errorMessages={['this field is required']}
             className="material-form__field"
            />             */}
             </Col>
             </Row>
             <br/>
             <Row>
             <Col md={12} lg={12}> 
             <label>Description</label> 
        <Editor
                    editorState={editorState}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={this.onEditorStateChange}
/>


          </Col>
          </Row>
          <br />
          <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Submit')
                    }
                </Button>
               
          </ValidatorForm>
          </CardBody>
    </Card>
 
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
    </Row>
  </Container>
    )

  }

}



export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(SendNotification));
