import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';


import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider , { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;
const tableColumns = [ 
  {
    dataField: "uid",
    text: "#",
    sort: true,
    headerStyle: { width:"10%" }
  },  
  {
      dataField: "first_name",
      text: "Name",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "mobile_no",
      text: "Mobile",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "gender",
      text: "Gender",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "age",
      text: "Age",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "blood_group",
      text: "Blood Group",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "date_of_birth",
      text: "DOB",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "action",
      text: "Action",
      headerStyle: { width:"10%" },
      csvExport:false
    },
  ];

class Admin_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: []
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'id', filterable: true },
      { title: 'First Name', prop: 'first_name', filterable: true },
      { title: 'Middle Name', prop: 'middle_name', filterable: true },
      { title: 'Last Name', prop: 'last_name', filterable: true },
      { title: 'User Email', prop: 'email' },
      { title: 'User Mobile No.', prop: 'mobile_no'},
      { title: 'Gender', prop: 'gender'},
      { title: 'User Age', prop: 'age'},
      { title: 'Blood Group', prop: 'blood_group'},
      { title: 'Date of Birth', prop: 'date_of_birth'},
      { title: 'User Status', prop: 'status'},
      { title: 'Action', prop: 'action',cell: (row)=><div><i id={row.id} className="fa fa-remove ptr_css" onClick={() =>this.remove(row.id)}></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-edit ptr_css"></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-eye ptr_css"></i></div> },
      ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    
  }

  remove(e) { 
    this.flag = 1;
    fetch(myConstClass.BASE_URL+`/user/deleteAdmin`, {
    method: 'POST',
    body: e,
  }).then(toast('User Deleted Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS}),

  fetch(myConstClass.BASE_URL+'/user/getAdmin')
  .then(response => response.json())
  .then(response =>{
    let i=1;
    response.forEach(element => {
      let id=element.uid;
    element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() =>this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css"></i></div>;
     // console.log(element);
     element.uid=i;
     i++;
    });
    // this.setState({posts: response})
    this.setState({formData: response})
  })
  .catch(err => console.log(err))
  )
  .catch();

  
  }

  edit(e) {    
   // alert(e);
    this.props.history.push('/forms/add_admin/'+e);
   }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    fetch(myConstClass.BASE_URL+'/user/getAdmin')
    .then(response => response.json())
    .then(response =>{
      let i=1;
      response.forEach(element => {
        let id=element.uid;
      element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() =>this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() =>this.edit(id)}></i></div>;
       // console.log(element);
       element.uid=i;
       i++;
      });
      // this.setState({posts: response})
      this.setState({formData: response})
      
    })
    .catch(err => console.log(err))

    if(!localStorage.getItem('session_id')){
      console.log('History:',this.props);      
      this.props.history.push('/log_in');
  }
        
  }

render() {
  
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All Users
        <Link to="/forms/add_admin" style={{float:'right'}}> <Button
                    color="primary"
                    variant="contained"
                    type="button"
                   
                >Add Admin</Button>
                </Link></h3>
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
    <ToolkitProvider
              keyField="name"
              data={this.state.formData}
              columns={tableColumns}
              exportCSV
              search
            >
              {props => (
                    <div>
                        
                          <CardBody>
                          <SearchBar { ...props.searchProps } />
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              bordered={false}
                              pagination={paginationFactory({
                                // sizePerPage: 25,
                                sizePerPage: 10,
                                sizePerPageList: [5, 10, 25, 50]
                              })}
                            />
                          </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
      {/* <CardBody> */}
      
    {/* <Datatable
  tableHeader={this.header}
  tableBody={this.state.posts}
  keyName="userTable"
  tableClass="striped hover responsive"
  rowsPerPage={10}
  rowsPerPageOption={[5, 10, 15, 20]}
  initialSort={{prop: "`title`", isAscending: true}}
  onSort={this.onSortFunction}
  labels={this.customLabels}
  
/> */}

    {/* <MDBDataTable
      striped
      hover
      data={this.state.posts}
    /> */}
      {/* </CardBody> */}
      </Card>
      </Col>
    </Row>
  </Container>)
}
}



export default withTranslation('common')(Admin_list);
