import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, paging, MDBPagination } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import '../../../common_db.css';
import 'font-awesome/css/font-awesome.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
var CryptoJS = require("crypto-js");

const { SearchBar } = Search;

const tableColumns = [
  {
    dataField: "uid",
    text: "#",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "first_name",
    text: "Name",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "mobile_no",
    text: "Mobile No",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "designation",
    text: "Designation",
    headerStyle: { width: "10%" },
    csvExport: false
  }, {
    dataField: "branch_name",
    text: "Branch Name",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "department",
    text: "Department",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "gender",
    text: "Gender",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  // {
    // dataField: "dob",
    // text: "DOB",
    // headerStyle: { width: "10%" },
    // csvExport: false
  // },
  {
    dataField: "status",
    text: "Status",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "action",
    text: "Actions",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      array_t: [],
      tableRows: [],
      formData: [],
      formDataCSV: '',
      prev_page_id: '',
      next_page_id: '',
      isLoaded: false,
    };
    this.change = this.change.bind(this);
    this.encode = this.encode.bind(this);
    this.NextPageRecords = this.NextPageRecords.bind(this);
    this.PreviousPageRecords = this.PreviousPageRecords.bind(this);
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'id', filterable: true },
      { title: 'Employee Name', prop: 'first_name', filterable: true },
      { title: 'Email', prop: 'email' },
      { title: 'Mobile No.', prop: 'mobile_no' },
      { title: 'Designation', prop: 'designation' },
      { title: 'Branch', prop: 'branch_name' },
      { title: 'Department', prop: 'department' },
      { title: 'Vertical', prop: 'vertical' },
      { title: 'Gender', prop: 'gender' },
      { title: 'Date of Birth', prop: 'date_of_birth' },
      { title: 'Age', prop: 'age' },
      { title: 'Status', prop: 'status' },
      { title: 'Action', prop: 'action', cell: (row) => <div><i id={row.id} className="fa fa-remove ptr_css" onClick={() => this.remove(row.id)}></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-edit ptr_css" onClick={() => this.edit(row.id)}></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-eye ptr_css"></i></div> },
    ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };

  }

  remove(e) {
    this.flag = 1;
    var status = '1';
    var next_page_id = this.state.next_page_id - 1;
    fetch(myConstClass.BASE_URL + `/user/deleteUser`, {
      method: 'POST',
      body: e,
    })

      .then(response => response.json()).then((response) => {
        toast('User Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS });
        if (response != false) {

          fetch(myConstClass.BASE_URL + '/user/getUserListEncode/' + status + "/" + next_page_id)
            .then(response => response.json()
            ).then(response => {
              const responseNewArray = response.map(item => ({
                uid: atob(item.uid),
                // first_name: atob(item.first_name),
                // email: atob(item.email),
                // mobile_no: atob(item.mobile_no),
                // designation: atob(item.designation),
                // branch_name: atob(item.branch_name),
                // department: atob(item.department),
                // gender: atob(item.gender),
                // date_of_birth: atob(item.date_of_birth),
                // status: atob(item.status),
                first_name: this.encode(item.first_name),
                email: this.encode(item.email),
                mobile_no: this.encode(item.mobile_no),
                designation: this.encode(item.designation),
                branch_name: this.encode(item.branch_name),
                department: this.encode(item.department),
                gender: this.encode(item.gender),
                date_of_birth: this.encode(item.date_of_birth),
                status: this.encode(item.status),

                prv_page_id: (item.prv_page_id),
                nxt_page_id: (item.nxt_page_id),
                sr_id: (item.sr_id),
              }));
              let i = 1;
              responseNewArray.forEach(element => {
                let id = element.uid;
                element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i></div>;
                // element.uid = i;
                element.uid = element.sr_id;
                i++;
              });
              // this.setState({posts: response})
              this.setState({ prev_page_id: responseNewArray[0]['prv_page_id'] });
              this.setState({ next_page_id: responseNewArray[0]['nxt_page_id'] });
              this.setState({ formData: responseNewArray, isLoaded: true })
            }).catch(err => console.log(err))
        }
        console.log(response);
        console.log(response)
      }).catch();

    //  .then(toast('User Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS }),
    // ).catch();
  }

  change(event) {
    let arr;
    this.setState({ isLoaded: false })

    fetch(myConstClass.BASE_URL + '/user/getUserListEncode/' + event.target.value + '/' + 1)
      .then(response => response.json())
      .then(response => {
        const responseNewArray = response.map(item => ({
          uid: atob(item.uid),
          // first_name: atob(item.first_name),
          // email: atob(item.email),
          // mobile_no: atob(item.mobile_no),
          // designation: atob(item.designation),
          // branch_name: atob(item.branch_name),
          // department: atob(item.department),
          // gender: atob(item.gender),
          // date_of_birth: atob(item.date_of_birth),
          // status: atob(item.status),

          first_name: this.encode(item.first_name),
          email: this.encode(item.email),
          mobile_no: this.encode(item.mobile_no),
          designation: this.encode(item.designation),
          branch_name: this.encode(item.branch_name),
          department: this.encode(item.department),
          gender: this.encode(item.gender),
          date_of_birth: this.encode(item.date_of_birth),
          status: this.encode(item.status),

          prv_page_id: (item.prv_page_id),
          nxt_page_id: (item.nxt_page_id),
          sr_id: (item.sr_id),
        }));
        let i = 1;
        responseNewArray.forEach(element => {
          let id = element.uid;
          element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i></div>;
          arr = this.state.array_t;
          arr.push(element);
          this.setState({ array_t: arr });
          // element.uid = i;
          element.uid = element.sr_id;
          i++;
        });
        if (responseNewArray[0]) {
          this.setState({ prev_page_id: responseNewArray[0]['prv_page_id'] });
          this.setState({ next_page_id: responseNewArray[0]['nxt_page_id'] });
        } else {
          this.setState({ prev_page_id: 0 });
          this.setState({ next_page_id: 0 });
        }
        this.setState({ formData: responseNewArray, isLoaded: true })
      })
      .catch(err => console.log(err))
  }

  edit(e) {
    this.props.history.push('/forms/add/' + e);
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    let arr;
    var status = "1";
    fetch(myConstClass.BASE_URL + '/user/getUserListEncode/' + status + '/' + 1)
      .then(response => response.json())
      .then(response => {
        const responseNewArray = response.map(item => ({
          uid: atob(item.uid),
          // first_name: atob(item.first_name),
          // email: atob(item.email),
          // mobile_no: atob(item.mobile_no),
          // designation: atob(item.designation),
          // branch_name: atob(item.branch_name),
          // department: atob(item.department),
          // gender: atob(item.gender),
          // date_of_birth: atob(item.date_of_birth),
          // status: atob(item.status),

          first_name: this.encode(item.first_name),
          email: this.encode(item.email),
          mobile_no: this.encode(item.mobile_no),
          designation: this.encode(item.designation),
          branch_name: this.encode(item.branch_name),
          department: this.encode(item.department),
          gender: this.encode(item.gender),
          date_of_birth: this.encode(item.date_of_birth),
          status: this.encode(item.status),

          prv_page_id: (item.prv_page_id),
          nxt_page_id: (item.nxt_page_id),
        }));
        let i = 1;
        responseNewArray.forEach(element => {
          let id = element.uid;
          element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} onClick={() => this.edit(id)} className="fa fa-edit ptr_css"></i></div>;
          arr = this.state.array_t;
          arr.push(element);
          this.setState({ array_t: arr });
          element.uid = i;
          i++;
        });
        this.setState({ formData: responseNewArray, isLoaded: true })
        this.setState({ prev_page_id: responseNewArray[0]['prv_page_id'] });
        this.setState({ next_page_id: responseNewArray[0]['nxt_page_id'] });
      })
      .catch(err => console.log(err))

    fetch(myConstClass.BASE_URL + `/user/getUsersCSV`)
      .then(response => response.json())
      .then(response => this.setState({ formDataCSV: response }))

    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  encode(txt) {
    var passphrase = "userpanicevidencepassphrase"; //"passphrase";
    //  var encrypted_json_string ={"ciphertext":"slK9cfydbX\/0oH3l4xnt6g==","iv":"eecce0f76446e862a19fcd1f3c17ff75","salt":"b219654ab7da3c05735c56d16b4d20e984b1a9c3e7db07700dd5baf92c9bb48dafd6fd14783561e1639cb61720b99588e4bf35af11d4d090bb4fe8712f5086a2cb90ea98a545e8209c518c778c704d1ebb93781b0a880f43bd6bd3723d8f42d0a2125fb3828939571a76037556c657d8f30d7e0ece0dbaab36fed89ad7e298107ccffcbbf9af1626282f72848934b545473c33b2a67731787203e5d72752f6cb8bd9e79179d76f250e9f4e724abba7d2e1d7e3bba99192c5694499b3047b8ced8bab5552e283a1dcc3febf7507c8b4981c634e762208846c2e3e5ef79e80625824a637b40a512c9327be052a354d98ddad966ce8f569b192965fea8d27b4ac6f"};
    var obj_json = JSON.parse(txt);
    // var encrypted = obj_json.ciphertext;
    var encrypted = obj_json.ct;
    // var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    var salt = CryptoJS.enc.Hex.parse(obj_json.st);
    var iv = CryptoJS.enc.Hex.parse(obj_json.iv);
    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  NextPageRecords(next_page_id) {
    //alert(next_page_id)
    this.setState({ isLoaded: false })
    var status = '1';
    fetch(myConstClass.BASE_URL + '/user/getUserListEncode/' + status + "/" + next_page_id)
      .then(response => response.json())
      .then(response => {
        const responseNewArray = response.map(item => ({
          uid: atob(item.uid),
          // first_name: atob(item.first_name),
          // email: atob(item.email),
          // mobile_no: atob(item.mobile_no),
          // designation: atob(item.designation),
          // branch_name: atob(item.branch_name),
          // department: atob(item.department),
          // gender: atob(item.gender),
          // date_of_birth: atob(item.date_of_birth),
          // status: atob(item.status),

          first_name: this.encode(item.first_name),
          email: this.encode(item.email),
          mobile_no: this.encode(item.mobile_no),
          designation: this.encode(item.designation),
          branch_name: this.encode(item.branch_name),
          department: this.encode(item.department),
          gender: this.encode(item.gender),
          date_of_birth: this.encode(item.date_of_birth),
          status: this.encode(item.status),

          prv_page_id: (item.prv_page_id),
          nxt_page_id: (item.nxt_page_id),
          sr_id: (item.sr_id),
        }));
        let ii = 1;
        responseNewArray.forEach(element => {
          let id = element.id;
          element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} onClick={() => this.edit(id)} className="fa fa-edit ptr_css"></i></div>;
          element.uid = element.sr_id;
          ii++;
        });
        this.setState({ prev_page_id: responseNewArray[0]['prv_page_id'] });
        this.setState({ next_page_id: responseNewArray[0]['nxt_page_id'] });
        this.setState({ formData: responseNewArray, isLoaded: true })
      })
      .catch(err => console.log(err))
  }
  PreviousPageRecords(prev_page_id) {
    this.setState({ isLoaded: false })
    var status = '1';
    fetch(myConstClass.BASE_URL + '/user/getUserListEncode/' + status + "/" + prev_page_id)
      .then(response => response.json())
      .then(response => {
        const responseNewArray = response.map(item => ({
          uid: atob(item.uid),
          // first_name: atob(item.first_name),
          // email: atob(item.email),
          // mobile_no: atob(item.mobile_no),
          // designation: atob(item.designation),
          // branch_name: atob(item.branch_name),
          // department: atob(item.department),
          // gender: atob(item.gender),
          // date_of_birth: atob(item.date_of_birth),
          // status: atob(item.status),

          first_name: this.encode(item.first_name),
          email: this.encode(item.email),
          mobile_no: this.encode(item.mobile_no),
          designation: this.encode(item.designation),
          branch_name: this.encode(item.branch_name),
          department: this.encode(item.department),
          gender: this.encode(item.gender),
          date_of_birth: this.encode(item.date_of_birth),
          status: this.encode(item.status),
          prv_page_id: (item.prv_page_id),
          nxt_page_id: (item.nxt_page_id),
          sr_id: (item.sr_id),
        }));
        let ii = 1;
        responseNewArray.forEach(element => {
          let id = element.id;
          element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} onClick={() => this.edit(id)} className="fa fa-edit ptr_css"></i></div>;
          element.uid = element.sr_id;
          ii++;
        });
        this.setState({ prev_page_id: responseNewArray[0]['prv_page_id'] });
        this.setState({ next_page_id: responseNewArray[0]['nxt_page_id'] });
        this.setState({ formData: responseNewArray, isLoaded: true })
      })
      .catch(err => console.log(err))
  }
  render() {
    const { prev_page_id, next_page_id, isLoaded } = this.state;
    const headers = [
      { label: "#", key: "uid" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Mobile No.", key: "mobile_no" },
      { label: "Designation", key: "designation" },
      { label: "Branch", key: "branch_name" },
      { label: "Department", key: "department" },
      { label: "Gender", key: "gender" },
      // { label: "DOB", key: "dob" },
      { label: "Status", key: "status" },
    ];

    const columns = [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Mobile No',
        field: 'mobile_no',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Designation',
        field: 'designation',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Branch Name',
        field: 'branch_name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Department',
        field: 'department',
        sort: 'asc',
        width: 100
      },
      {
        label: 'gender',
        field: 'gender',
        sort: 'asc',
        width: 100
      },
      // {
      //   label: 'DOB',
      //   field: 'dob',
      //   sort: 'asc',
      //   width: 100
      // },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'asc',
        width: 100
      }
    ];

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">All Users</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <Row>
                        <Col md={4} lg={4}></Col>
                        <Col md={4} lg={4}>
                          <FormGroup>
                            <Input type="select" name="select" id="exampleSelect" onChange={this.change}>
                              <option value="0">Select Status</option>
                              <option value="1">Active</option>
                              <option value="2">In-active</option>
                              <option value="3">Suspended</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={4} lg={4}>
                          <Link to="/forms/add" style={{ float: 'right' }}> <Button
                            color="primary"
                            variant="contained"
                            type="button"
                          >Add Employee</Button>
                          </Link>
                          {this.state.formDataCSV ?
                            <CSVLink filename={"Users.csv"}
                              className="btn btn-secondary" style={{ 'float': 'right', 'margin': '0px 17px' }} data={this.state.formDataCSV} headers={headers}>Export</CSVLink>
                            : ''}
                        </Col>
                      </Row>
                      <hr style={{ margin_bottom: '2px !important', margin_top: '0px !important' }} />
                      {!isLoaded ? (
                        <div style={{ display: 'flex' }}  >
                          <div className="load__icon-wrap">
                            <svg className="load__icon">
                              <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                            </svg>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <SearchBar {...props.searchProps} />
                          <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            bordered={false}
                          // pagination={paginationFactory({
                          //   sizePerPage: 10,
                          //   sizePerPageList: [5, 10, 25, 50]
                          // })}
                          />
                          <div style={{ justifyContent: "center", display: "flex", marginTop: '10px' }}>
                            {this.state.prev_page_id == 0 ? (null
                            ) : <button class="page-link" onClick={() => this.PreviousPageRecords(prev_page_id)} >{"<<<"}   Previous Page </button>}
                            &nbsp;&nbsp;&nbsp;
                            {this.state.next_page_id == 0 ? (null
                            ) : <button class="page-link" onClick={() => this.NextPageRecords(next_page_id)} >  Next Page {">>>"} </button>}
                          </div>
                        </div>)}
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Users);
