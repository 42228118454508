import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { changeToLight, changeToDark } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Dashboard" icon="home" route="/dashboard_default" onClick={this.hideSidebar} />
          <SidebarCategory title="Manage Users" icon="users">
            <SidebarLink title="Add Employee" route="/forms/add" onClick={this.hideSidebar} />
            <SidebarLink title="Add Bulk Employee" route="/forms/addbulk" onClick={this.hideSidebar} />
            {/* <SidebarLink title="List Employee" route="/forms/users" onClick={this.hideSidebar} /> */}
            <SidebarLink title="List Employee" route="/forms/users_list" onClick={this.hideSidebar} />
          </SidebarCategory>
          {localStorage.getItem('utype') == '1' ? <SidebarCategory title="Manage Admin" icon="user">        
            <SidebarLink title="Add Admin" route="/forms/add_admin" onClick={this.hideSidebar} />
            <SidebarLink title="List Admin" route="/forms/admin_list" onClick={this.hideSidebar} />
          </SidebarCategory>:''}
           
          {/* <SidebarLink icon="eye" title="Panic Evidence" route="/forms/evidence_list" onClick={this.hideSidebar} /> */}
          <SidebarLink icon="eye" title="Panic Evidence" route="/forms/panic_evidence_list" onClick={this.hideSidebar} />
          <SidebarLink icon="user" title="Employee Cop" route="/forms/cop_list" onClick={this.hideSidebar} />
          <SidebarCategory title="Manage Locations" icon="map-marker">
            <SidebarLink title="Add Bulk Location" route="/forms/addLocation" onClick={this.hideSidebar} />
            <SidebarLink title="List Location" route="/forms/locations" onClick={this.hideSidebar} />
            <SidebarLink title="Group Location" route="/forms/group_locations" onClick={this.hideSidebar} />
            <SidebarLink title="Tracking" route="/forms/tracking" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="MIS Report" icon="file-empty">
            <SidebarLink title="Download user report" route="/forms/MisReport/user_report" onClick={this.hideSidebar} />
            <SidebarLink title="Panic Alerts Report" route="/forms/MisReport/panic_alerts_report" onClick={this.hideSidebar} />
          </SidebarCategory>
          {localStorage.getItem('utype') == '1' ? 
          <div>
          <SidebarLink icon="alarm" title="Notifications" route="/forms/notifications" onClick={this.hideSidebar} />
          <SidebarLink icon="alarm" title="Pre Trigger Notifications" route="/forms/pre_trigger_notifications" onClick={this.hideSidebar} />
          <SidebarLink icon="envelope" title="View Queries" route="/forms/queries" onClick={this.hideSidebar} />
          <SidebarCategory title="CMS Pages" icon="file-empty">
            <SidebarLink title="About Us" route="/forms/about_us" onClick={this.hideSidebar} />
            <SidebarLink title="Privacy Policy" route="/forms/privacy_policy" onClick={this.hideSidebar} />
            <SidebarLink title="Terms and Conditions" route="/forms/terms" onClick={this.hideSidebar} />
            <SidebarLink title="Help" route="/forms/help" onClick={this.hideSidebar} />
            <SidebarLink title="Tutorial" route="/forms/tutorial" onClick={this.hideSidebar} />
          </SidebarCategory></div> :''}
        </ul>
       
      </div>
    );
  }
}

export default SidebarContent;
